import React, { useState } from "react";
import moment from "moment";
import Styles from "./MenteeSessions.module.css";
import { UpBlueChevron, DollarIcon, CalendarIcon } from "../../Icons";
import AvatarPlaceholder from "../../../images/avatar-placeholder.gif";
import { ALLOWED_STATUS } from "entities/Session";
import { useNavigate } from "react-router-dom";
import { MenteeSessionQueryResult } from "entities/MenteeSession";
import TimeSlotSelector from "../../Molecules/TimeSlotSelector/TimeSlotSelector";
import useMyProfile from "hooks/useMyProfile";
import useAcceptSession from "hooks/mentor/session/useAcceptSession";
import useDeclineSession from "hooks/mentor/session/useDeclineSession";
import { ThreeDots } from "react-loader-spinner";
import ShowDetailsButton from "../../Atoms/ShowDetailsButton/ShowDetailsButton.component";

interface SessionCardProps {
  session: MenteeSessionQueryResult;
}

import dayjs from "utils/datetime";

interface ProposedTimesProps {
  proposed_times: string[];
  timezone: string;
}

const ProposedTimes: React.FC<ProposedTimesProps> = ({
  proposed_times,
  timezone,
}) => {
  return (
    <div className={Styles.proposed_times}>
      <h4>Your proposed times:</h4>
      <ul>
        {proposed_times.map((time, index) => (
          <li key={index}>
            {dayjs(time).tz(timezone).format("ddd, MMM D, h:mm A z")}
          </li>
        ))}
      </ul>
    </div>
  );
};

const MenteeSessionRequestCard = ({ session }: SessionCardProps) => {
  const [show_details, setshow_details] = useState(false);
  const navigate = useNavigate();
  const { profile } = useMyProfile();
  const { accept, is_loading: accept_session_loading } = useAcceptSession();
  const { decline, is_loading: decline_session_loading } = useDeclineSession();

  const handleAcceptSession = async (slot: string): Promise<void> => {
    accept(session._id, slot, () => {
      window.location.reload();
    });
  };

  const handleDeclineSession = async () => {
    decline(session._id, "", () => {
      window.location.reload();
    });
  };

  const handleRescheduleSession = async () => {
    navigate(`/reschedule/${session._id}`);
  };

  const toggleDetails = () => setshow_details(!show_details);

  const formatSessionDuration = (duration: number) => {
    if (!duration) return "";
    return {
      30: "30 min session",
      60: "1 hour session",
    }[duration];
  };

  return (
    <div className={Styles.sessionCardContainer}>
      <div className={Styles.sessionCard}>
        <img
          className={Styles.avatar}
          alt="avatar"
          src={session.mentor_profile?.profileImage?.url || AvatarPlaceholder}
        />
        <div className={Styles.cardInfo}>
          <div className={Styles.detail}>
            <div className={Styles.basicDetail}>
              <h3>{`${session.mentor_profile.given_name} ${session.mentor_profile.family_name}`}</h3>
              <p className={Styles.objective}>{session.mentor_profile.headline || "No headline provided."}</p>
            </div>
          </div>

          <div className={Styles.infoSection}>
            <div className={Styles.dateInfo}>
              <img
                width="15px"
                height="15px"
                src={CalendarIcon}
                alt="calendar-icon"
              />
              <p className={Styles.session_details_text}>
                {moment(session.ideal_proposed_time).format("llll")} -{" "}
                {formatSessionDuration(session.duration)}
              </p>
            </div>
            <div className={Styles.dateInfo}>
              <img
                width="15px"
                height="15px"
                src={DollarIcon}
                alt="credits-icon"
              />
              <p className={Styles.session_details_text}>
                {(session.duration / 30) * session.mentor_profile.hourlyRate.tokens / 2} {(session.duration / 30) * session.mentor_profile.hourlyRate.tokens / 2 === 1 ? 'credit' : 'credits'}
              </p>
            </div>
          </div>
        </div>
        <ShowDetailsButton
          show_details={show_details}
          toggle_details={toggleDetails}
        />
      </div>
      {show_details && (
        <div className={Styles.details_container}>
          <div>
            <div className={Styles.agenda}>
              <h4>Agenda</h4>
              <p>{session.description}</p>
            </div>
          </div>
          {session.proposed_by == "mentor" &&
            session.status === ALLOWED_STATUS.RESCHEDULE && (
              <div className={Styles.requested_session_action_container}>
                <h3>Reschedule Request</h3>
                <p className={Styles.reschedule_message}>
                  Your mentor has requested to reschedule this session. Please
                  choose a new date from the options below:
                </p>
                <TimeSlotSelector
                  timezone={profile.timezone}
                  inital_slot={session.ideal_proposed_time}
                  slots={session.proposed_times}
                  note={session.reschedule_note}
                  accept_session_loading={accept_session_loading}
                  confirmSession={handleAcceptSession}
                  decline_session_loading={decline_session_loading}
                  handleRescheduleSession={handleRescheduleSession}
                  handleDeclineSession={handleDeclineSession}
                />
              </div>
            )}
          {session.status === ALLOWED_STATUS.RESCHEDULE &&
            session.proposed_by === "mentee" && (
              <div className={Styles.reschedule_status}>
                <p className={Styles.reschedule_message}>
                  Your reschedule request has been sent. We're waiting for a
                  response from your mentor.
                </p>
                <ProposedTimes
                  proposed_times={session.proposed_times}
                  timezone={profile.timezone}
                />
              </div>
            )}
          {[ALLOWED_STATUS.REQUEST].includes(session.status) && (
            <div>
              <ProposedTimes
                proposed_times={session.proposed_times}
                timezone={profile.timezone}
              />
              <div className={Styles.action_buttons}>
                <button
                  onClick={handleRescheduleSession}
                  className={Styles.black_button}
                >
                  Propose other times
                </button>
                <button
                  onClick={handleDeclineSession}
                  className={Styles.red_button}
                >
                  {" "}
                  {decline_session_loading ? (
                    <ThreeDots
                      // style={{ marginRight: "2px" }}
                      color="#FFF"
                      height={18}
                      width={24}
                    />
                  ) : (
                    "Decline session"
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MenteeSessionRequestCard;
