// webapp/src/components/Molecules/SessionStatusTag/SessionStatusTag.tsx
import React from "react";
import { ALLOWED_STATUS } from "entities/Session";
import { CancelCircle, TickSign } from "../../Icons";
import styles from "./SessionStatusTag.module.css";

interface SessionStatusTagProps {
  status: string;
  duration: number;
}

const SessionStatusTag = ({ status, duration }: SessionStatusTagProps) => {
  const formatSessionDuration = (duration: number) => {
    if (!duration) return "";
    return {
      30: "30 min",
      60: "1 hour",
    }[duration];
  };

  const getStatusText = () => {
    switch (status) {
      case ALLOWED_STATUS.COMPLETED:
        return "Session Complete";
      case ALLOWED_STATUS.DECLINED:
        return "Session Declined";
      case ALLOWED_STATUS.EXPIRED:
        return "Session Expired";
      case ALLOWED_STATUS.UPCOMING:
        return "Upcoming Session";
      case ALLOWED_STATUS.RESCHEDULE:
        return "Reschedule Requested";
      case ALLOWED_STATUS.CANCELED:
        return "Session Canceled";
      default:
        return "Session Status";
    }
  };

  return (
    <div className={styles.dateInfo}>
      {status === ALLOWED_STATUS.COMPLETED ? (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM11.8536 5.14645C12.0488 5.34171 12.0488 5.65829 11.8536 5.85355L6.85355 10.8536C6.65829 11.0488 6.34171 11.0488 6.14645 10.8536L3.14645 7.85355C2.95118 7.65829 2.95118 7.34171 3.14645 7.14645C3.34171 6.95118 3.65829 6.95118 3.85355 7.14645L6.5 9.79289L11.1464 5.14645C11.3417 4.95118 11.6583 4.95118 11.8536 5.14645Z"
            fill="#15B881"  /* New green color */
          />
        </svg>
      ) : [ALLOWED_STATUS.DECLINED, ALLOWED_STATUS.EXPIRED, ALLOWED_STATUS.CANCELED].includes(status) ? (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM4.85355 4.14645C4.65829 3.95118 4.34171 3.95118 4.14645 4.14645C3.95118 4.34171 3.95118 4.65829 4.14645 4.85355L6.79289 7.5L4.14645 10.1464C3.95118 10.3417 3.95118 10.6583 4.14645 10.8536C4.34171 11.0488 4.65829 11.0488 4.85355 10.8536L7.5 8.20711L10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536C11.0488 10.6583 11.0488 10.3417 10.8536 10.1464L8.20711 7.5L10.8536 4.85355C11.0488 4.65829 11.0488 4.34171 10.8536 4.14645C10.6583 3.95118 10.3417 3.95118 10.1464 4.14645L7.5 6.79289L4.85355 4.14645Z"
            fill="#FB836F"  /* New red color */
          />
        </svg>
      ) : null}
      <p>{getStatusText()}</p>
    </div>
  );
};

export default SessionStatusTag;
