import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../BookSession.module.css";
//@ts-ignore
import _refiner from "refiner-js";
import { useParams } from "react-router-dom";
import { clearRequestData } from "../../../../../store/dux/booking/booking.actions";
import BookingSessionSuccessComponent from "./BookSessionSuccess.component";
import useMentor from "hooks/useMentor";
import { Grid } from "react-loader-spinner";

interface CurrentBookingProperties {
	mentor_given_name: string;
	session_duration: string;
	session_iso_datetime: string;
}

const BookingSessionSuccess = () => {
	const { slug } = useParams<Record<string, string | undefined>>();
	const dispatch = useDispatch();
	const booking = useSelector((state: any) => state.booking);
	const { mentor } = useMentor({ slug });
	const [current_booking, setCurrentBooking] = useState<CurrentBookingProperties | undefined>();
	const [is_loading, setIsLoading] = useState(true);

	useEffect(() => {
		if (mentor) {
			setCurrentBooking({
				session_duration: booking.duration,
				session_iso_datetime: booking.ideal_proposed_time,
				mentor_given_name: mentor?.given_name
			});
			dispatch(clearRequestData());
		}
	}, [mentor])

	useEffect(() => {
		console.log("BookingSessionSuccess", booking);
		setIsLoading(false);
		setTimeout(
			() => _refiner("showForm", "512b42b0-d28f-11eb-a456-95e279856d5c"),
			1000
		);
	}, []);

	return (
		<div className={styles.successPage}>
			{!current_booking || !mentor || is_loading ? (
				<Grid wrapperStyle={{ margin: "auto", display: "block" }} color="#5440F0" height={42} width={42} />
			) : (
				<BookingSessionSuccessComponent
					is_loading={is_loading}
					session_duration={current_booking?.session_duration}
					session_iso_datetime={current_booking?.session_iso_datetime}
					mentor_given_name={mentor.given_name}
					mentor_profile_image_url={mentor?.profileImage?.url}
				/>)}
		</div>
	);
};

export default BookingSessionSuccess;
